import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

export default function TermsOfService() {
  
  /* ***********************************************
 *  Render
 * ************************************************/
  return (
    <>
      <div className="terms">
        <Header />
        <main>
          <div className="wrapper">
            <h2>realtimecounter サービス利用規約</h2>
            <h3>第１条（定義）</h3>
            <ol>
              <li>「本利用規約」とは、本realtimecounter利用規約、並びに、当社が本条第２項に定義する本サービスに関するWebサイト上（以下「本サービスWEBサイト」という）において別途利用者に提示する本サービスの運用に係るルール、注意事項をいいます。</li>
              <li>「本サービス」とは、コボルト株式会社（以下「当社」といいます）が提供する、利用者のWEBサイト上に当該WEBサイトのリアルタイムでの閲覧人数を表示するシステムにかかるサービスをいいます。</li>
            </ol>
            <h3>第2条（アカウントの開設）</h3>
            <ol>
              <li>本サービスの利用者になろうとする方は、本利用規約の内容に同意の上、当社が指定する情報の登録を含む、当社が別途定める手続によりユーザー登録を行い、アカウントを開設するものとします。なお、本サービスの利用者になろうとする方が未成年者その他の制限行為能力者である場合には、事前に法定代理人の同意を得て、本利用規約への同意及びユーザー登録を行うものとします。</li>
              <li>利用者は、前項に基づき登録した情報に変更が発生した場合、直ちに、登録情報の変更手続を行う義務を負います。</li>
              <li>当社は、当社の裁量により、利用者になろうとする方によるアカウントの開設をその事前または事後に拒否する場合があります。</li>
              <li>利用者は、開設したアカウントを第三者に対して利用、貸与、譲渡、売買又は質入等をすることはできません。</li>
            </ol>
            <h3>第３条（本サービスの利用及び対価）</h3>
            <ol>
              <li>当社は、利用者に対して、本利用規約に基づき本サービスを提供するものとし、利用者は、当該サービスの利用に際して、本利用規約に定める義務を誠実に遵守するものとします。</li>
              <li>当社は、本サービスの提供の全部または一部を第三者に委託できるものとし、利用者はかかる委託を承諾するものとします。</li>
              <li>本サービスの利用にかかる対価は、無償とします。</li>
            </ol>
            <h3>第４条（本利用規約の変更）</h3>
            <ol>
              <li>当社は、法令の範囲内で、本利用規約の内容の変更を行うことができるものとします。本利用規約の変更を行う場合、当社は、本利用規約を変更する旨及び変更後の本利用規約の内容並びにその効力発生時期を本サービスWebサイト上において周知するものとします。</li>
              <li>利用者は、前項に基づく変更後の本利用規約に同意できない場合、本サービスの利用を終了するものとし、変更後の本利用規約の効力が生じた後に、利用者が、本サービスを利用した場合には、変更後の本利用規約の内容に同意したものとします。</li>
            </ol>
            <h3>第５条（個人情報の取扱い）</h3>
            <p>当社は、本サービスにおいて、利用者の個人情報を取り扱う場合は、当社が別途定めるプライバシーポリシーに従うものとします。</p>
            <h3>第６条（免責事項）</h3>
            <ol>
              <li>利用者は、本サービスを利用するために必要なハードウェア、ネットワーク及び本サービスを適用するWebサイト等の設備等の確保、並びに、本サービスを利用するために必要な設定等を、自己の責任と負担により行わなければなりません。利用者が当該設備等を確保できなかったり、本サービスの利用開始に必要な設定等ができない等、当社の責めに帰すべき事由によることなく、利用者が本サービスの利用を開始できない場合には、当社は一切の責任を負わないものとします。</li>
              <li>
                当社は、利用者に対して、本サービスに関して、明示、黙示を問わず、以下及び本条第３項の各事項を含め、一切の表明及び保証を行わないものとします。
                <ol className="bracket">
                  <li>本サービスにエラー、バグ、瑕疵等の不具合や脆弱性、あるいは本サービスによって表示される閲覧人数を含め本サービスに不正確な点がないこと</li>
                  <li>本サービスが中断なく完全に動作すること</li>
                  <li>本サービスの使用が、利用者または第三者に損害を与えないこと</li>
                  <li>本サービスが特定の目的に適合すること</li>
                  <li>本サービスの使用が第三者の知的財産権を侵害せず、若しくは侵害を引き起こさないこと</li>
                  <li>本サービスの使用結果に関する完全性、適格性、正確性、有用性および適法性</li>
                </ol>
              </li>
              <li>本サービスの動作や機能が依存する可能性のある、当社または第三者の製品、ソフトウェアまたはネットワークその他のサービスは、当該製品、ソフトウェアまたはサービスの提供者の判断により、その機能等の全部または一部が変更され、または提供や稼働が中断もしくは終了する場合があります。当社は本サービスの動作や機能が依存する可能性のあるこれらの製品、ソフトウェアまたはサービスが、変更、中断なく将来に亘って正常に提供され、稼動することについて、一切の表明及び保証を行わないものとします。</li>
            </ol>
            <h3>第７条（第三者に対する責任）</h3>
            <p>利用者が本サービスを利用することにより、第三者との間で権利侵害その他の事由により紛争を生じたときは、利用者自身が自らの費用と責任で解決するものとし、当社に一切の損害を生ぜしめないものとします。</p>
            <h3>第８条（禁止事項）</h3>
            <p>利用者は、本サービスの利用に関し、次の行為をしてはならないものとします。なお、利用者の行為が以下の項目に該当するか否かは、当社が合理的に判断するものとし、利用者は、当社の判断に対し、何らの異議を申し立てないものとします。</p>
            <ol className="bracket">
              <li>当社に虚偽の事項を届け出る行為</li>
              <li>法令の定めに違反する場合</li>
              <li>犯罪に結びつく行為およびその可能性のある行為</li>
              <li>公序良俗に反する行為</li>
              <li>当社の判断に錯誤を与えるおそれのある行為</li>
              <li>当社および当社の委託先（委託先の従業員等も含みます。）、他の利用者を含む第三者に対して、権利（知的財産権を含みます）の侵害、プライバシーの侵害、誹謗中傷その他の不利益を与える行為またはそのおそれのある行為</li>
              <li>当社または第三者を差別または誹謗中傷する行為</li>
              <li>当社または第三者の信用を損なう行為</li>
              <li>当社のサービス業務（本サービスを含みますがこれに限られません。）の運営・維持を妨げる行為</li>
              <li>本サービスに関し利用しうる情報を改竄する行為</li>
              <li>有害なコンピュータープログラム等を送信または書き込む行為</li>
              <li>本サービスの仕様を当社の同業他社等第三者に漏洩する行為</li>
              <li>実現不可能な内容を登録する行為</li>
              <li>他人になりすまして本サービスを利用する行為</li>
              <li>当社の承認した以外の方法により、本サービスを利用する行為</li>
              <li>当社または第三者に対する迷惑行為</li>
              <li>その他当社が不適切と判断した行為</li>
            </ol>
            <h3>第９条（反社会的勢力）</h3>
            <ol>
              <li>利用者は、当社に対し、自らが反社会的勢力に該当しないことを表明し、かつ本サービスの利用期間中該当しないことを保証するものとします。なお、本条において「反社会的勢力」とは、暴力団員による不当な行為の防止等に関する法律（以下「暴対法」という）第2条第2号に定義される暴力団、暴対法第2条第6号に定義される暴力団員、暴力団員でなくなった日から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋、社会運動標榜ゴロ、政治活動標榜ゴロ、特殊知能暴力集団、暴力団密接関係者及びその他の暴力的な要求行為若しくは法的な責任を超えた不当要求を行う集団又は個人をいいます。</li>
              <li>
                利用者は、本サービスの利用に関連して自ら又は第三者を利用して以下の各号に該当する行為を行わないことを、当社に対し、保証するものとします。
                <ol className="bracket">
                  <li>暴力的な要求行為</li>
                  <li>法的な責任を超えた不当な要求行為</li>
                  <li>脅迫的な言動をし、又は暴力を用いる行為</li>
                  <li>風説を流布し、偽計又は威力を用いて他方当事者の信用を棄損し、又は他方当事者の業務を妨害する行為</li>
                  <li>その他前各号に準ずる行為</li>
                </ol>
              </li>
              <li>当社は、利用者が前２項の表明保証に違反した場合、又は、利用者による本サービスの利用が反社会的勢力の活動を助長し若しくは反社会的勢力の運営に資すると判明した場合には、かかる事由が生じた時点以降いつ何時においても、何らの催告を要することなく、利用者による本サービスの利用を停止させることができるものとします。</li>
              <li>前項の規定に基づき本サービスの利用を停止した場合、当社は、当該停止に起因して利用者に損害が生じた場合であっても、何らこれを賠償ないし補償することを要しないものとします。</li>
            </ol>
            <h3>第10条（本サービスの停止及び本サービスの廃止）</h3>
            <ol>
              <li>
                当社は、以下の各号に掲げる場合、本サービスの全部または一部を、利用者への予告なく停止することができ、これに起因して利用者または第三者に損害が発生した場合、当社は、賠償責任を負わないものとします。
                <ol className="bracket">
                  <li>第8条を含む本利用規約中のいずれかの条項に違反した場合</li>
                  <li>当社から利用者に対する連絡がとれなくなった場合</li>
                  <li>定期的または緊急に、本サービスの提供のためのシステムの保守または点検を行う場合（第三者提供サービスの仕様変更に伴う場合を含みます。）</li>
                  <li>火災、停電、天災地変等の非常事態により、本サービスの提供が困難または不能となった場合</li>
                  <li>戦争、内乱、暴動、騒擾、労働争議、感染症の蔓延等により、本サービスの提供が困難または不能となった場合</li>
                  <li>本サービスの提供のためのシステムの不良および第三者からの不正アクセス、コンピューターウィルスの感染等により本サービスの提供が困難または不能となった場合</li>
                  <li>法令等に基づく措置により、本サービスの提供が困難または不能となった場合</li>
                  <li>第三者提供サービスまたは当社が本サービスを提供する上で利用する第三者のサービスの停止または終了（保守、仕様の変更、瑕疵の修補による停止を含みますが、これらに限りません。）により、本サービスの提供が困難または不能となった場合</li>
                  <li>その他当社が止むを得ないと判断した場合</li>
                </ol>
              </li>
              <li>当社は、本サービスの全部または一部を、利用者への予告なく改訂、追加、変更、修正（アップデート）または廃止することができ、これに起因して利用者または第三者に損害が発生した場合であっても、当社は、賠償責任を負わないものとします。</li>
            </ol>
            <h3>第11条 （権利の帰属）</h3>
            <ol>
              <li>本サービスに関する一切の知的財産権は、当社又は当社にライセンスを許諾している者に帰属するものとし、本サービスの利用を以て、利用者はその他の第三者に対し、当社又は当社にライセンスを許諾している者の知的財産権の使用許諾を付与するものではありません。</li>
              <li>本サービス上、当社または本サービスにかかる商標、ロゴ及びサービスマーク等（以下、総称して「商標等」といいます。）が表示される場合がありますが、当社は、利用者その他の第三者に対し、商標等を譲渡し、又は本利用規約で明示する以外の使用を許諾するものではありません。</li>
            </ol>
            <h3>第12条 （秘密保持）</h3>
            <ol>
              <li>
                利用者は、当社の事前の書面による承諾がある場合を除き、本サービスに関連して当社が開示した一切の情報（以下「秘密情報」といいます）を秘密に取り扱うものとし、第三者に開示、漏洩してはならず、本サービスの利用以外の目的で使用してはならないものとします。但し、次の各号のいずれかに該当する当社の秘密情報については、この限りではありません。
                <ol className="bracket">
                  <li>開示時に既に自ら合法的に所有していたもの</li>
                  <li>開示時に既に公知であったもの</li>
                  <li>開示後に利用者の責に帰すべからざる事由により公知となったもの</li>
                  <li>正当な権限を有する第三者より利用者が秘密保持義務を伴わず入手したもの</li>
                  <li>当社の秘密情報とは無関係に利用者が独自に開発又は知得した情報</li>
                </ol>
              </li>
              <li>利用者は、当社から求められた場合はいつでも、当社の指示に従い、遅滞なく、当社の秘密情報及び当該情報を記載又は記録した書面その他の記録媒体物並びにその全ての複製物等を返却又は廃棄しなければなりません。</li>
            </ol>
            <h3>第13条（本利用規約の有効性）</h3>
            <ol>
              <li>本利用規約の一部の規定が法令に基づいて無効と判断されても、本利用規約のその他の規定は有効とします。</li>
              <li>本利用規約の全部または一部の規定が、ある利用者との関係で無効とされ、または取り消された場合でも、本利用規約はその他の利用者との関係では有効とします。</li>
            </ol>
            <h3>第14条（権利義務の譲渡禁止）</h3>
            <ol>
              <li>利用者は、当社の事前の承諾なく、本利用規約に基づく権利または義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分（以下「譲渡等」といいます。）をすることはできないものとします。本項に反して本利用規約に基づく権利または義務を第三者に譲渡等した場合、当社は、催告をせず、本契約を直ちに解除できるものとします。</li>
              <li>当社が本サービスにかかる事業を第三者に譲渡し、又は当社が消滅会社若しくは分割会社となる合併若しくは会社分割等により本サービスに係る事業を包括承継させたときは、当社は、当該事業譲渡等に伴い、本サービスに関する本利用規約上の地位、権利及び義務並びに利用者情報その他の利用者に関する情報を当該事業譲渡等の譲受人又は承継人に譲渡することができるものとし、利用者は、予めこれに同意するものとします。</li>
            </ol>
            <h3>第15条（準拠法・専属管轄）</h3>
            <p>本利用規約の準拠法は日本法とし、本利用規約に関する紛争の第一審の専属的合意管轄裁判所は、訴額に応じ、東京地方裁判所または東京簡易裁判所とします。</p>
            <h3>第16条（協議解決）</h3>
            <p>当社及び利用者は、本利用規約に定めのない事項又は本利用規約の解釈に疑義が生じた場合には、互いに協議の上速やかに解決を図るものとします。</p>
            <time>2021年11月1日制定・施行</time>
          </div>
        </main>
        <Footer />
      </div>
    </>
  )
}