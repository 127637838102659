import { API } from 'aws-amplify'
import * as mutations from '../graphql/mutations'

export default async function pushLog({
  type, file, line, userID, websiteID, client, newError, errorObject
}) {
  const now = new Date()
  const input = {
    userID: userID,
    websiteID: websiteID,
    client: client,
    message: newError.message,
    file: file,
    line: line,
    deleteTime: Math.ceil(now.getTime() / 1000) + 10000,
  }
  switch (type) {
    case "INFO":
      await API.graphql({ query: mutations.createInfoLog, variables: { input: input } })
      break;
    case "ERROR":
      await API.graphql({ query: mutations.createErrorLog, variables: { input: input } })
      break;
    case "WARNING":
      await API.graphql({ query: mutations.createWarningLog, variables: { input: input } })
      break;
    default:
      break;
  }
  console.log('[logging] ', input.message, input, errorObject)
}