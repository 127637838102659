import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { SiteContext } from '../App'
import { isEmpty, passwordCheck } from '../functions'
import Header from '../components/Header';
import Footer from '../components/Footer';

const initalFormState = {
  email: '',
  password: '',
  rePassword: '',
  authCode: ''
}

export default function ForgotPassword() {

  const history = useHistory();
  /* ***********************************************
 *  state 
 * ************************************************/
  const { state, dispatch } = useContext(SiteContext)
  const [formState, updateFormState] = useState(initalFormState)
  const [messageState, setMessageState] = useState('')
  const [formEnableState, setFormEnableState] = useState(false)

  /* ***********************************************
 *  Function redirect
 * ************************************************/
  useEffect(() => {
    history.push(state.redirect)
  }, [history, state.redirect])

  /* ***********************************************
 *  Function redirect
 * ************************************************/
  useEffect(() => {
    if (state.isSignedIn) {
      dispatch({ type: 'REDIRECT', paht: '/mypage' })
    }
  }, [dispatch, state.isSignedIn])

  /* ***********************************************
 *  Function amplify hub
 * ************************************************/
  const onChange = (e) => {
    e.preventDefault();
    if (e.target.name === 'password') {
      const check = passwordCheck(e.target.value)
      setMessageState(check.message)
      setFormEnableState(check.enable)
    }
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }))
  }

  /* ***********************************************
 *  Function amplify hub
 * ************************************************/
  const onClick = (e) => {
    e.preventDefault();
    setMessageState('')
    dispatch({ type: 'UPDATE-MESSAGE', key: 'forgotPassword', value: '' })
    dispatch({ type: 'UPDATE-MESSAGE', key: 'forgotPasswordSubmit', value: '' })
    if (isEmpty(formState.password) || isEmpty(formState.email) || isEmpty(formState.authCode)) {
      setMessageState('メールアドレス、パスワード、または認証コードが入力されていません')
      return null
    }
    if (formState.password !== formState.rePassword) {
      setMessageState('パスワードが一致しません')
      return null
    }

    if (formEnableState) {
      dispatch({
        type: 'fORGOT-PASSWORD-SUBMIT',
        email: formState.email, password: formState.password, authCode: formState.authCode
      })
    }
  }

  /* ***********************************************
 *  Render
 * ************************************************/
  return (
    <>
      <div className="admin before-login">
        <Header />
        <main>
          <section>
            <h2>
              {"パスワードの再設定"}
            </h2>
            <p className="description">
              パスワードの再設定に必要な認証コードを送信します。<br/>
              ご登録のメールアドレスを入力して下さい。
            </p>
            <div className="signup-form">
              <input
                id="email"
                type="email"
                name="email"
                placeholder='メールアドレス'
                value={formState.email}
                onChange={onChange}
              />
              <button onClick={() => dispatch({ type: 'fORGOT-PASSWORD', email: formState.email })} className="button">
                {'認証コード発行'}
              </button>
            </div>
            <div className="signup-form">
              <input
                id="password"
                type="password"
                name="password"
                placeholder='新しいパスワード'
                value={formState.password}
                onChange={onChange}
              />
              <input
                id="re-password"
                type="password"
                name="rePassword"
                placeholder='パスワード確認'
                value={formState.rePassword}
                onChange={onChange}
              />
              <input
                id="auth-code"
                type="text"
                name="authCode"
                placeholder='認証コード'
                value={formState.authCode}
                onChange={onChange}
              />
              <p className="form-message">
                {messageState}
              </p>
              <p className="form-message">
                {state.message.forgotPassword}
                {state.message.forgotPasswordSubmit}
              </p>
              <button onClick={onClick} className="button">
                {'パスワード変更'}
              </button>
            </div>
            <p className="to-otherform">
              <Link to='/login' onClick={() => dispatch({ type: 'REDIRECT', path: '/login' })}>{'ログイン画面に戻る'}</Link>
            </p>
          </section>
        </main>
        <Footer />
      </div>
    </>
  )
}
