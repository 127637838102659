import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

export default function PrivacyPolicy() {
  
  /* ***********************************************
 *  Render
 * ************************************************/
  return (
    <>
      <div className="terms">
        <Header />
        <main>
          <div className="wrapper">
            <h2>プライバシーポリシー</h2>
            <p>コボルト株式会社（以下「当社」といいます。）は、当社が提供する、Webサイト上にリアルタイムでの閲覧人数を表示するrealtimecounterシステム（以下、「本システム」といいます）を利用する方（以下「利用者」といいます。）の個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます）を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性を認識させるとともにその取組みを徹底させることにより、個人情報の保護を推進します。</p>
            <h3>第 1 条 （個人情報）</h3>
            <p>「個人情報」とは、個人情報の保護に関する法律（以下、「個人情報保護法」といいます。）にいう「個人情報」を指し、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別できるもの又は個人識別符号が含まれるものを指します。</p>
            <h3>第 2 条 （個人情報の取得と利用）</h3>
            <p>当社は、以下の目的に必要な範囲で、利用者の個⼈情報を取得し、取得した情報を利用する場合があります。以下の⽬的の範囲を超えて個⼈情報を利⽤する場合には、事前に適切な⽅法で利用者からの同意を得るものとします。</p>
            <ol className="bracket">
              <li>本システムを提供するため</li>
              <li>メンテナンス、重要なお知らせなど必要に応じたご連絡のため</li>
              <li>本システムに関する利用者からのお問い合わせに回答するため（本人確認を行うことを含む）</li>
              <li>利用規約に違反した利用者や、不正・不当な目的で本システムを利用しようとする利用者の特定をし、ご利用をお断りするため</li>
              <li>本システムの内容を改良・改善し、又は新しいサービスを開発するため</li>
              <li>本システムの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスのご案内（電子メール、チラシ、その他のダイレクトメールの送付を含む）のため</li>
              <li>本システムの利用履歴等を調査・分析し、その結果を本システムの改良・開発や広告の配信に利用するため</li>
              <li>本システムに関するアンケート・取材等のご協力依頼や各種イベントへのご参加をお願いし、又はその結果などをご報告するため</li>
              <li>本システムの利用状況を利用者にご報告するため</li>
            </ol>
            <h3>第 3 条 （個人情報の管理と保護）</h3>
            <p>個人情報の管理は、厳重に行うこととし、次に掲げる場合を除き、利用者の同意がない限り、第三者に対しデータを開示・提供することはいたしません。 また、安全性を考慮し、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん及び漏えい等のリスクに対する予防並びに是正に関する対策を講じます。</p>
            <ol className="bracket">
              <li>人の生命、身体又は財産の保護のために必要がある場合であって、利用者の同意を得ることが困難である場合</li>
              <li>公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、利用者の同意を得ることが困難である場合</li>
              <li>国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用者の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合</li>
              <li>業務を円滑に遂行するため、利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合</li>
              <li>合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
              <li>個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的及び当該個人情報の管理について責任を有する者の氏名又は名称について、あらかじめ利用者に通知し、又は利用者が容易に知り得る状態に置いた場合</li>
              <li>その他法令で認められる場合</li>
            </ol>
            <h3>第 4 条 （個人情報の取扱いの委託）</h3>
            <p>当社は、利用目的の達成に必要な範囲内において、第三者に対して、個人情報の取扱いの全部又は一部を委託する場合があります。この場合、当社は、当該第三者に委託先としての適格性を十分審査するとともに、契約にあたって守秘義務に関する事項等を定め、委託先に対する必要かつ適切な監督を行います。また、当社は、取得個人情報の全部又は一部を、委託先との間で個人情報保護法の定めに基づいて共同利用することがあります。</p>
            <h3>第 5 条 （個人情報の開示）</h3>
            <p>当社は、利用者から個人情報の開示を求められたときは、利用者に対し、遅滞なくこれを開示します。但し、開示することにより次のいずれかに該当する場合は、その全部又は一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。</p>
            <ol className="bracket">
              <li>利用者又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
              <li>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
              <li>その他法令に違反することとなる場合</li>
            </ol>
            <h3>第 6 条 （個人情報の訂正及び削除）</h3>
            <ol>
              <li>当社の保有する個人情報が誤った情報である場合には、利用者の請求により、当社が定める手続きに従い個人情報の訂正又は削除を行います。</li>
              <li>当社は、利用者から前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正又は削除を行い、これを利用者に通知します。</li>
            </ol>
            <h3>第 7 条 （個人情報の利用停止等）</h3>
            <p>当社は、利用者から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、又は不正の手段により取得されたものであるという理由により、その利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨利用者に通知します。但し、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、利用者の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。</p>
            <h3>第 8 条 （本ポリシーの変更手続）</h3>
            <p>当社は本ポリシーの内容を適宜見直し、その改善に努めます。当社は、以下の場合には、本ポリシーの内容の変更を行うことができるものとします。本ポリシーの変更を行う場合、当社は、本ポリシーを変更する旨及び変更後の本ポリシーの内容並びにその効力発生時期を当社Webサイト上において周知するものとします。</p>
            <ol className="bracket">
              <li>当該変更が、利用者の一般の利益に適合する場合</li>
              <li>当該変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものである場合</li>
            </ol>
            <h3>第 9 条 （法令、規範の遵守）</h3>
            <p>当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守します。</p>
            <h3>第 10 条 （苦情及び相談への対応）</h3>
            <p>当社は、個人情報の取扱いに関する利用者からの苦情、相談を受け付け、適切かつ迅速に対応いたします。また、利用者からの当該個人情報の開示、訂正、追加、削除、利用又は提供の拒否などのご要望に対しても、迅速かつ適切に対応いたします。</p>
            <h3>第 11 条 （お問い合わせ窓口）</h3>
            <p>当社の個人情報の取扱に関するお問い合せは下記までご連絡ください。</p>
            <p>info@realtimecounter.link</p>
            <time>2021年11月1日制定・施行</time>
          </div>
        </main>
        <Footer />
      </div>
    </>
  )
}