import { API } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { SiteContext } from '../App'
import { pushLog } from '../functions'
import * as mutations from '../graphql/mutations'
import * as queries from '../graphql/queries'

const errorlogData = (userID, line, error, object) => {
  return {
    type: 'ERROR',
    file: 'ChangePassword.jsx',
    userID: userID,
    websiteID: null,
    client: null,
    line: line,
    newError: error,
    errorObject: object
  }
}

export default function WebsitDesign(props) {

  /* ***********************************************
 *  state 
 * ************************************************/
  const { state, dispatch } = useContext(SiteContext)
  const [styleTypeState, setStyleTypeState] = useState({})
  const [messageState, setMessageState] = useState('')

  /* ***********************************************
 *  Function update user data
 * ************************************************/
  useEffect(() => {
    if (state.session == null) return null;
    (async () => {
      const user = await API.graphql({ query: queries.getUser, variables: { id: state.session.attributes.sub } })
      dispatch({ type: 'UPDATE-KEY', key: 'user', value: user.data.getUser })
    })().catch(error => {
      pushLog(errorlogData(state.session.attributes.sub, 34, new Error('update user data error.'), error))
    })
  }, [dispatch, state.session])

  /* ***********************************************
 *  Function update form state
 * ************************************************/
  const onChange = (e) => {
    e.preventDefault();
    setStyleTypeState({ ...styleTypeState, [e.target.name]: e.target.value })
  }

  /* ***********************************************
 *  Function delete hostname
 * ************************************************/
  const changeStyleType = async (websiteID, key) => {
    try {
      await API.graphql({
        query: mutations.updateWebsite, variables: {
          input: {
            id: websiteID,
            styleType: styleTypeState[key]
          }
        }
      })
      const user = await API.graphql({ query: queries.getUser, variables: { id: state.session.attributes.sub } })
      dispatch({ type: 'UPDATE-KEY', key: 'user', value: user.data.getUser })
      setMessageState('変更しました')
    } catch (error) {
      setMessageState('変更できませんでした')
      pushLog(errorlogData(state.session.attributes.sub, 34, new Error('update user data error.'), error))
    }

  };

  /* ***********************************************
 *  Render
 * ************************************************/
  return (
    <section className="admin-settings">
      <h3>デザイン設定</h3>
      <p className="form-message">
        {messageState}
      </p>
      {state.user.websites.items.map((site, index) => {
        const key = "styleType" + index;
        return (
          <div key={index} className="section-body">
            <p className="site-name">
              <span>{site.hostname}</span>
              <span>{site.deleted ? '[無効]' : ''}</span>
            </p>
            <p>{site.styleType}</p>
            <div className="select-wrapper">
              <select
                id={site.id}
                name={key}
                value={styleTypeState[key]}
                onChange={onChange}
              >
                <option value="">---</option>
                {/* TODO : デザインのCSSを作ったら変更してください */}
                <option value="sample1">デザインのサンプル1</option>
                <option value="sample2">デザインのサンプル2</option>
                <option value="sample3">デザインのサンプル3</option>
                <option value="sample4">デザインのサンプル4</option>
                <option value="sample5">デザインのサンプル5</option>
              </select>
            </div>
            <span><button onClick={() => changeStyleType(site.id, key)} className="button">更新</button></span>
          </div>
        )
      })}
    </section>
  )
}
