import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SiteContext } from '../App'
import Footer from '../components/Footer';
import Header from '../components/Header';

export default function Home() {

  const history = useHistory();
  /* ***********************************************
 *  state 
 * ************************************************/
  const { state, dispatch } = useContext(SiteContext)

  /* ***********************************************
 *  Function
 * ************************************************/
  const handleLink = (path) => {
    dispatch({ type: 'REDIRECT', path: path })
    history.push(path)
  }
  /* ***********************************************
 *  Function redirect
 * ************************************************/
  useEffect(() => {
    history.push(state.redirect)
  }, [history, state.redirect])

  /* ***********************************************
 *  Render
 * ************************************************/
  return (
    <>
      <div className="lp">
        <Header />
        <main>
          <section className="block-hero">
            <div className="wrapper">
              <div>
                <h2>ネットショップ、<br/>ホームページの<br/>行列を見える化</h2>
                <h3>ホームページ閲覧人数を<br/>リアルタイムにカウントします</h3>
                <button onClick={() => handleLink('/signup')} className="button button-l">今すぐ無料ではじめる</button>
              </div>
            </div>
          </section>
          <section className="block-desc1">
            <div className="wrapper">
              <picture>
                <img src={require('../assets/images/lp/screen1_2x.png').default} alt="" />
              </picture>
              <div>
                <h4>今、ネットショップ、ホームページに<br/>アクセスしている閲覧人数が<br/>リアルタイムでわかります</h4>
                <p>リアルタイムカウンターは、ネットショップ、ホームページにアクセスしてるユーザー数をリアルタイムで検知し、分かりやすくカウントして表示します。</p>
              </div>
            </div>
          </section>
          <section className="block-desc2">
            <div className="wrapper">
              <picture>
                <img src={require('../assets/images/lp/screen2_2x.png').default} alt="" />
              </picture>
              <div>
                <h4>誰でも無料で<br/>手軽に利用できます</h4>
                <p>メールアドレスとパスワードを設定するだけで、リアルタイムカウンターを使用出来るソースコードを自動で発行します。お手持ちのネットショップ、ホームページのソースコードへ埋め込むだけで、簡単に設置が完了します。</p>
                <p className="note">※今後、機能拡張した有料版もリリース予定</p>
              </div>
            </div>
          </section>
          <section className="block-desc3">
            <div className="wrapper">
              <h4>動作確認をしているCMS・ネットショップ</h4>
              <ul className="note">
                <li>※2022年5月時点の情報です</li>
                <li>※JavaScriptが動くネットショップ、サーバーであれば基本的に稼働します</li>
                <li>※各サービス仕様によっては稼働いたしませんのでご了承ください</li>
              </ul>
              <ul className="logo-parade">
                <li><picture><img src={require('../assets/images/lp/logo_wordpress.png').default} alt="WordPress" /></picture></li>
                <li><picture><img src={require('../assets/images/lp/logo_jimdo.png').default} alt="JIMDO" /></picture></li>
                <li><picture><img src={require('../assets/images/lp/logo_peraichi.png').default} alt="ペライチ" /></picture></li>
                <li><picture><img src={require('../assets/images/lp/logo_shopify.png').default} alt="shopify" /></picture></li>
                <li><picture><img src={require('../assets/images/lp/logo_eccube.png').default} alt="EC CUBE" className="square" /></picture></li>
                <li><picture><img src={require('../assets/images/lp/logo_shopserve.png').default} alt="shop serve" /></picture></li>
                <li><picture><img src={require('../assets/images/lp/logo_makeshop.png').default} alt="MakeShop" /></picture></li>
                <li><picture><img src={require('../assets/images/lp/logo_aishipr.png').default} alt="aishipR" /></picture></li>
              </ul>
            </div>
          </section>
          <section className="block-cta">
            <div className="wrapper">
              <h4>簡単にはじめられます</h4>
              <p>ホームページ閲覧人数を<br/>リアルタイムにカウントします</p>
              <button onClick={() => handleLink('/signup')} className="button button-l">今すぐ無料ではじめる</button>
            </div>
          </section>
          <section className="block-news">
            <div className="wrapper">
              <h4>お知らせ</h4>
              <ul>
                <li>
                  <h5>2022.5.31</h5>
                  <p>リアルタイムカウンターβ版をリリースしました</p>
                </li>
              </ul>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </>
  )
}