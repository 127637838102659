import React, { useReducer, useEffect } from 'react';
import { API, Auth, Hub } from 'aws-amplify'
import reducer from './functions/reducer';
import Router from './Router';
import pushLog from './functions/pushLog';
import * as mutations from './graphql/mutations'
import * as queries from './graphql/queries'
import './assets/styles/App.css';
import './assets/styles/style.scss';

export const SiteContext = React.createContext()
export const initalState = {
  isSignedIn: false,
  redirect: '/',
  message: {},
  session: {},
  user: {}
}

const infologData = (line, error, object) => {
  return {
    type: 'INFO',
    file: 'App.jsx',
    userID: null,
    websiteID: null,
    client: null,
    line: line,
    newError: error,
    errorObject: object
  }
}
const errorlogData = (line, error, object) => {
  return {
    type: 'ERROR',
    file: 'App.jsx',
    userID: null,
    websiteID: null,
    client: null,
    line: line,
    newError: error,
    errorObject: object
  }
}

export default function App() {

  /* ***********************************************
 *  state 
 * ************************************************/
  const [state, dispatch] = useReducer(reducer, initalState)

  /* ***********************************************
 *  Function session
 * ************************************************/
  useEffect(() => {
    (async () => {
      const res = await Auth.currentAuthenticatedUser()
      const sub = res.attributes.sub
      const user = await API.graphql({ query: queries.getUser, variables: { id: sub } })
      dispatch({ type: 'AUTH-CHECK', session: res, user: user.data.getUser })
    })().catch(error => {
      if (error !== 'not authenticated') {
        pushLog(errorlogData(55, new Error('current authenticated user error.'), error))
      }
    })
  }, [])

  /* ***********************************************
 *  Function amplify hub
 * ************************************************/
  useEffect(() => {
    Hub.listen('auth', async (data) => {
      switch (data.payload.event) {

        // sign up
        case 'signUp':
          dispatch({ type: 'REDIRECT', path: '/signup-confirm' })
          pushLog(infologData(75, new Error(data.payload.message), data))
          break;

        case 'signUp_failure':
          if (data.payload.data.code === "UsernameExistsException") {
            dispatch({ type: 'UPDATE-MESSAGE', key: 'signup', value: 'メールアドレスに該当するユーザーが既に存在します' })
          }
          pushLog(errorlogData(80, new Error(data.payload.data.message), data))
          break;

        // sign in 
        case 'signIn':
          try {
            let user = null
            const email = data.payload.data.attributes.email
            const sub = data.payload.data.attributes.sub
            const res = await API.graphql({ query: queries.getUser, variables: { id: sub } })
            if (res.data.getUser == null) {
              const res = await API.graphql({
                query: mutations.createUser,
                variables: { input: { id: sub, username: email, serial: null } }
              })
              user = res.data.createUser
            } else {
              user = res.data.getUser
            }
            dispatch({
              type: 'UPDATE-ALL', state: {
                isSignedIn: true,
                session: data.payload.data,
                user: user,
                redirect: '/mypage',
                message: { ...state.message, signin: 'ログインしました' }
              }
            })
          } catch (error) {
            pushLog(errorlogData(89, new Error(error.message), error))
          }
          break;

        case 'signIn_failure':
          if (data.payload.data.code === "NotAuthorizedException") {
            dispatch({ type: 'UPDATE-MESSAGE', key: 'signin', value: 'ユーザー名またはパスワードが違います' })
          } else if (data.payload.data.code === "UserNotFoundException") {
            dispatch({ type: 'UPDATE-MESSAGE', key: 'signin', value: 'ユーザー名またはパスワードが違います' })
          } else {
            dispatch({ type: 'UPDATE-MESSAGE', key: 'signin', value: 'ログインに失敗しました' })
          }
          pushLog(errorlogData(116, new Error(data.payload.message), data))
          break;

        // forgot password
        case 'forgotPassword':
          dispatch({ type: 'UPDATE-MESSAGE', key: 'forgotPassword', value: 'メールアドレスに認証コードを送信しました' })
          console.log('setAuthListener forgotPassword:', data.payload.data.code)
          break;
        case 'forgotPassword_failure':
          dispatch({ type: 'UPDATE-MESSAGE', key: 'forgotPassword', value: 'メールアドレスに認証コードを送信しました。' })
          pushLog(errorlogData(133, new Error(data.payload.data.code + ':' + data.payload.message), data))
          break;

        // forgot password submit
        case 'forgotPasswordSubmit':
          dispatch({ type: 'UPDATE-MESSAGE', key: 'forgotPassword', value: 'パスワードを変更しました' })
          console.log('setAuthListener forgotPassword:', data.payload.data.code)
          break;
        case 'forgotPasswordSubmit_failure':
          pushLog(errorlogData(143, new Error(data.payload.data.code + ':' + data.payload.message), data))
          if (data.payload.data.code === 'CodeMismatchException') {
            dispatch({ type: 'UPDATE-MESSAGE', key: 'forgotPasswordSubmit', value: '認証コードが誤っています' })
          } else if (data.payload.data.code === 'UserNotFoundException') {
            dispatch({ type: 'UPDATE-MESSAGE', key: 'forgotPasswordSubmit', value: 'ユーザーが存在しません' })
          } else if (data.payload.data.code === 'InvalidParameterException') {
            dispatch({ type: 'UPDATE-MESSAGE', key: 'forgotPasswordSubmit', value: 'パスワードが基準に達していません' })
          } else {
            dispatch({ type: 'UPDATE-MESSAGE', key: 'forgotPasswordSubmit', value: 'パスワードの再発行に失敗しました' })
          }
          break;

        // sign out
        case 'signOut':
          // console.log('setAuthListener signOut:', data);
          break;

        case 'configured':
          console.log('setAuthListener configured:', data);
          break;

        default:
          console.log('setAuthListener:', data);
          break;
      }
    });
  }, [state.message])

  /* ***********************************************
 *  Render
 * ************************************************/
  return (
    <div className="App">
      <SiteContext.Provider value={{ state, dispatch }}>
        <Router />
      </SiteContext.Provider>
    </div>
  );
}
