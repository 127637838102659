import React, { useContext, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { SiteContext } from '../App'
import Footer from '../components/Footer';
import Header from '../components/Header';
import ChangePassword from '../components/ChangePassword';
import WebsitSubmit from '../components/WebsiteSubmit';
import WebsitDesign from '../components/WebsiteDesign';
import SignIn from '../components/SignIn';

export default function MyPage() {

  const { state, dispatch } = useContext(SiteContext)
  const [displayType, setDisplayType] = useState('home')

  const host = "https://js.realtimecounter.link";
  const divString = '<div id="realtimecounter" key="' + state.user.id + '"></div>';
  const scriptString = '<script type="text/javascript" src="' + host + '/realtimecounter.js"></script>';

  if (state.isSignedIn) {
    return (
      <>
        <div className="admin after-login">
          <Header />
          <main className="mypage">
            <nav className="mypage-nav">
              <ul>
                <li>
                  <a href="#" className={displayType === 'home' && 'on' } onClick={() => setDisplayType('home')}><span>ホーム</span></a>
                </li>
                <li>
                  <a href="#" className={displayType === 'counter' && 'on' } onClick={() => setDisplayType('counter')}><span>サイトの登録</span></a>
                </li>
                <li>
                  <a href="#" className={displayType === 'changePW' && 'on' } onClick={() => setDisplayType('changePW')}><span>パスワード変更</span></a>
                </li>
                <li>
                  <a href="#" onClick={() => dispatch({ type: 'SIGNOUT' })}><span>ログアウト</span></a>
                </li>
                <li>
                  <a href="https://forms.gle/QrLMbkWH8sSwKyQw8" target="_blank"><span>お問い合わせ</span></a>
                </li>
              </ul>
            </nav>
            <div className="mypage-body">
              {displayType === 'home' &&
                <section className="admin-howtouse">
                  <h3>ご利用方法</h3>
                  <div className="section-body">
                    <ol>
                      <li>
                        <h4>STEP 1</h4>
                        <div>
                          <p>
                            <a href="#" onClick={() => setDisplayType('counter')}>サイトの登録</a>
                            で、リアルタイムカウンターを設置するサイトを登録します。
                          </p>
                        </div>
                      </li>
                      <li>
                        <h4>STEP 2</h4>
                        <div>
                          <p>以下のコードを、登録したサイトの<code>&lt;body&gt;</code>タグ内の最後（<code>&lt;/body&gt;</code>タグ直前）に貼り付けます。</p>
                          <p>※全ページ共通で下記のコードを貼り付けてください</p>
                          <code>
                            {divString}
                            {scriptString}
                            </code>
                          <CopyToClipboard text={divString + "\n" + scriptString} onCopy={() => alert('コピーしました')}>
                            <button className="button button-s">クリップボードにコピー</button>
                          </CopyToClipboard>
                        </div>
                      </li>
                      <li>
                        <h4>STEP 3</h4>
                        <div>
                          <p>登録したサイトにリアルタイムカウンターが表示されていることをご確認ください。</p>
                        </div>
                      </li>
                    </ol>
                  </div>
                </section>
              }
              {displayType === 'counter' &&
                <>
                  <WebsitSubmit />
                  <section className="admin-register">
                    <h3>リアルタイムカウンター発行</h3>
                    <div className="section-body">
                      <p>以下のコードを、登録したサイトの<code>&lt;body&gt;</code>タグ内の最後（<code>&lt;/body&gt;</code>タグ直前）に貼り付けます。</p>
                      <p>※全ページ共通で下記のコードを貼り付けてください</p>
                      <code>
                        {divString}
                        {scriptString}
                      </code>
                      <CopyToClipboard text={divString + "\n" + scriptString} onCopy={() => alert('コピーしました')}>
                        <button className="button button-s">クリップボードにコピー</button>
                      </CopyToClipboard>
                    </div>
                  </section>
                </>
              }
              {displayType === 'design' &&
                <WebsitDesign />
              }
              {displayType === 'changePW' &&
                <ChangePassword />
              }
            </div>
          </main>
          <Footer />
        </div>
      </>
    )
  } else {
    return (
      <SignIn />
    )
  }
}