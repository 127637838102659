import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { SiteContext } from '../App'
import { isEmpty, passwordCheck } from '../functions'
import Header from '../components/Header';
import Footer from '../components/Footer';

const initalFormState = {
  email: '',
  password: '',
  rePassword: '',
}

export default function SignUp() {

  const history = useHistory();
  /* ***********************************************
 *  state 
 * ************************************************/
  const { state, dispatch } = useContext(SiteContext)
  const [formState, updateFormState] = useState(initalFormState)
  const [messageState, setMessageState] = useState('')
  const [formEnableState, setFormEnableState] = useState(false)
  
  const handleLink = (path) => {
    dispatch({ type: 'REDIRECT', path: path })
    history.push(path)
  }
  
  /* ***********************************************
 *  Function redirect
 * ************************************************/
  useEffect(() => {
    history.push(state.redirect)
  }, [history, state.redirect])

  /* ***********************************************
 *  Function redirect
 * ************************************************/
  useEffect(() => {
    if (state.isSignedIn) {
      dispatch({ type: 'REDIRECT', paht: '/mypage' })
    }
  }, [dispatch, state.isSignedIn])

  /* ***********************************************
 *  Function amplify hub
 * ************************************************/
  const onChange = (e) => {
    e.preventDefault();
    if (e.target.name === 'password') {
      const check = passwordCheck(e.target.value)
      setMessageState(check.message)
      setFormEnableState(check.enable)
    }
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }))
  }

  /* ***********************************************
 *  Function amplify hub
 * ************************************************/
  const onClick = (e) => {
    e.preventDefault();
    if (isEmpty(formState.password) || isEmpty(formState.email)) {
      setMessageState('メールアドレス、またはパスワードが入力されていません')
      return null
    }
    if (formState.password !== formState.rePassword) {
      setMessageState('パスワードが一致しません')
      return null
    }

    if (formEnableState) {
      dispatch({ type: 'SIGNUP', email: formState.email, password: formState.password })
    }
  }

  /* ***********************************************
 *  Render
 * ************************************************/
  return (
    <>
      <div className="admin before-login">
        <Header />
        <main>
          <section>
            <h2>
              {"新しいアカウントを作成"}
            </h2>
            <p className="description">
              <Link to='/terms' target='_blank'>利用規約</Link>、<Link to='/privacy' target='_blank'>プライバシーポリシー</Link>にご同意いただける場合は、次へお進みください。
            </p>
            <div className="signup-form">
              <input
                id="email"
                type="email"
                name="email"
                placeholder='メールアドレス'
                value={formState.email}
                onChange={onChange}
              />
              <input
                id="password"
                type="password"
                name="password"
                placeholder='パスワード'
                value={formState.password}
                onChange={onChange}
              />
              <input
                id="re-password"
                type="password"
                name="rePassword"
                placeholder='パスワード（確認）'
                value={formState.rePassword}
                onChange={onChange}
              />
              <p className="form-message">
                {messageState}
              </p>
              <p className="form-message">
                {state.message.signup}
              </p>
              <button onClick={onClick} className="button">
                {'認証コードを送信'}
              </button>
            </div>
            <p className="to-otherform">
              アカウントをお持ちの場合は
              <Link to='/login' onClick={() => handleLink('/login')}>{'ログイン'}</Link>
            </p>
          </section>
        </main>
        <Footer />
      </div>
    </>
  )
}
