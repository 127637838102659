/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      serial
      authType
      updatedAt
      createdAt
      websites {
        items {
          id
          userID
          hostname
          styleType
          deleted
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        serial
        authType
        updatedAt
        createdAt
        websites {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getWebsite = /* GraphQL */ `
  query GetWebsite($id: ID!) {
    getWebsite(id: $id) {
      id
      userID
      user {
        id
        username
        serial
        authType
        updatedAt
        createdAt
        websites {
          nextToken
        }
      }
      hostname
      styleType
      deleted
      updatedAt
      createdAt
      accessLog {
        items {
          id
          websiteID
          client
          url
          deleteTime
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const listWebsites = /* GraphQL */ `
  query ListWebsites(
    $filter: ModelWebsiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebsites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        user {
          id
          username
          serial
          authType
          updatedAt
          createdAt
        }
        hostname
        styleType
        deleted
        updatedAt
        createdAt
        accessLog {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAccessLog = /* GraphQL */ `
  query GetAccessLog($id: ID!) {
    getAccessLog(id: $id) {
      id
      websiteID
      client
      url
      deleteTime
      updatedAt
      createdAt
    }
  }
`;
export const listAccessLogs = /* GraphQL */ `
  query ListAccessLogs(
    $filter: ModelAccessLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccessLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        websiteID
        client
        url
        deleteTime
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getErrorLog = /* GraphQL */ `
  query GetErrorLog($id: ID!) {
    getErrorLog(id: $id) {
      id
      userID
      websiteID
      client
      message
      file
      line
      deleteTime
      updatedAt
      createdAt
    }
  }
`;
export const listErrorLogs = /* GraphQL */ `
  query ListErrorLogs(
    $filter: ModelErrorLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listErrorLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        websiteID
        client
        message
        file
        line
        deleteTime
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getInfoLog = /* GraphQL */ `
  query GetInfoLog($id: ID!) {
    getInfoLog(id: $id) {
      id
      userID
      websiteID
      client
      message
      file
      line
      deleteTime
      updatedAt
      createdAt
    }
  }
`;
export const listInfoLogs = /* GraphQL */ `
  query ListInfoLogs(
    $filter: ModelInfoLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInfoLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        websiteID
        client
        message
        file
        line
        deleteTime
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getWarningLog = /* GraphQL */ `
  query GetWarningLog($id: ID!) {
    getWarningLog(id: $id) {
      id
      userID
      websiteID
      client
      message
      file
      line
      deleteTime
      updatedAt
      createdAt
    }
  }
`;
export const listWarningLogs = /* GraphQL */ `
  query ListWarningLogs(
    $filter: ModelWarningLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWarningLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        websiteID
        client
        message
        file
        line
        deleteTime
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
