import { Auth } from 'aws-amplify'
import { initalState } from '../App'

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE-ALL':
      return { ...action.state }
    case 'UPDATE-KEY':
      return { ...state, [action.key]: action.value }
    case 'UPDATE-MESSAGE':
      return { ...state, message: { ...state.message, [action.key]: action.value } }
    case 'AUTH-CHECK':
      return { ...state, redirect: '/mypage', session: action.session, user: action.user, isSignedIn: true }
    case 'REDIRECT':
      return { ...state, redirect: action.path }
    case 'SIGNUP':
      Auth.signUp({ username: action.email, password: action.password, attributes: { email: action.email } })
      return { ...state, message: { ...state.message, email: action.email, signUpAction: 'サインアップ処理を実行' } }
    case 'SIGNIN':
      Auth.signIn(action.email, action.password)
      return { ...state, message: { ...state.message, signInAction: 'サインイン処理を実行' } }
    case 'fORGOT-PASSWORD':
      Auth.forgotPassword(action.email)
      return { ...state, message: { ...state.message, forgotPasswordAction: 'パスワード再発行処理を実行' } }
    case 'fORGOT-PASSWORD-SUBMIT':
      Auth.forgotPasswordSubmit(action.email, action.authCode, action.password)
      return { ...state, message: { ...state.message, forgotPasswordSubmitAction: 'パスワード再発行認証処理を実行' } }
    case 'SIGNOUT':
      if (state.isSignedIn) Auth.signOut()
      return { ...initalState, message: { ...state.message, signOutAction: 'サインアウト変更処理を実行' } }
    default:
      return state
  }
}

export default reducer;