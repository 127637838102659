import React, { useContext } from 'react';
import { SiteContext } from '../App'
import { Link } from 'react-router-dom';

export default function Header(props) {

  const { state, dispatch } = useContext(SiteContext);

  /* ***********************************************
 *  Render
 * ************************************************/
  if (state.isSignedIn === true) {
    return (
      <header>
        <div className="wrapper">
          <h1>
            <Link to='/' onClick={() => dispatch({ type: 'REDIRECT', path: '/' })}>
              <picture><img src={require('../assets/images/lp/logo.svg').default} alt="Realtime Counter"/></picture>
            </Link>
          </h1>
          <div className="header-menu">
            <input type="checkbox" id="menu-state" />
            <label for="menu-state" className="icon-user pulldown">{state.user?.username}</label>
            <ul>
              <li>
                <Link to='/mypage' onClick={() => dispatch({ type: 'REDIRECT', path: '/mypage' })}>
                  {'マイページ'}
                </Link>
              </li>
              <li>
                <Link to='/' onClick={() => dispatch({ type: 'SIGNOUT'})}>ログアウト</Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
    )
  } else {
    return (
      <header>
        <div className="wrapper">
          <h1>
            <Link to='/' onClick={() => dispatch({ type: 'REDIRECT', path: '/' })}>
              <picture><img src={require('../assets/images/lp/logo.svg').default} alt="Realtime Counter"/></picture>
            </Link>
          </h1>
          <nav className="pc">
            <ul>
              <li><Link to='/login' onClick={() => dispatch({ type: 'REDIRECT', path: '/login' })}>ログイン</Link></li>
              <li><Link to='/signup' onClick={() => dispatch({ type: 'REDIRECT', path: '/signup' })} className={'button'}>無料登録</Link></li>
            </ul>
          </nav>
          <input type="checkbox" id="nav-state"/>
          <nav className="mobile">
            <label for="nav-state">Menu</label>
            <div>
              <ul>
                <li><Link to='/' onClick={() => dispatch({ type: 'REDIRECT', path: '/' })}>ホーム</Link></li>
                <li><a href="https://kobold-inc.com/" target="_blank" rel="noreferrer">運営会社</a></li>
                <li><Link to='/login' onClick={() => dispatch({ type: 'REDIRECT', path: '/login' })}>ログイン</Link></li>
                <li><Link to='/signup' onClick={() => dispatch({ type: 'REDIRECT', path: '/signup' })}>無料登録</Link></li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    )
  }

}