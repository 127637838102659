import { API } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { SiteContext } from '../App'
import { isEmpty, pushLog } from '../functions'
import * as mutations from '../graphql/mutations'
import * as queries from '../graphql/queries'

const errorlogData = (userID, line, error, object) => {
  return {
    type: 'ERROR',
    file: 'ChangePassword.jsx',
    userID: userID,
    websiteID: null,
    client: null,
    line: line,
    newError: error,
    errorObject: object
  }
}
const MAX_FREE_WEBSITE_ITEMS = 2;
const MAX_PAID_WEBSITE_ITEMS = 5;

export default function WebsitSubmit(props) {

  /* ***********************************************
 *  state 
 * ************************************************/
  const { state, dispatch } = useContext(SiteContext)
  const [hostnameState, setHostnameState] = useState('')
  const [messageState, setMessageState] = useState('')
  const [maxWebsiteState, setMaxWebsiteState] = useState(MAX_FREE_WEBSITE_ITEMS)

  /* ***********************************************
 *  Function update user data
 * ************************************************/
  useEffect(() => {
    if (state.session == null) return null;
    (async () => {
      const user = await API.graphql({ query: queries.getUser, variables: { id: state.session.attributes.sub } })
      dispatch({ type: 'UPDATE-KEY', key: 'user', value: user.data.getUser })
      if(user.data.getUser.authType === 'PAID') setMaxWebsiteState(MAX_PAID_WEBSITE_ITEMS)
    })().catch(error => {
      pushLog(errorlogData(state.session.attributes.sub, 34, new Error('update user data error.'), error))
    })
  }, [dispatch, state.session])

  /* ***********************************************
 *  Function update form state
 * ************************************************/
  const onChange = (e) => {
    e.preventDefault();
    setHostnameState(e.target.value)
  }

  /* ***********************************************
 *  Function add hostname
 * ************************************************/
  const addHostname = async () => {
    if (isEmpty(hostnameState)) {
      setMessageState('ホスト名に値が入っていません')
      return null
    }
    const hostnameList = state.user.websites.items.map(site => site.hostname)
    if (hostnameList.some(h => h === hostnameState)) {
      setMessageState('すでに登録しています')
    } else {
      try {
        await API.graphql({
          query: mutations.createWebsite,
          variables: {
            input: {
              userID: state.user.id,
              hostname: hostnameState,
              deleted: false,
              styleType: 'default',
            }
          }
        })
        const user = await API.graphql({ query: queries.getUser, variables: { id: state.session.attributes.sub } })
        dispatch({ type: 'UPDATE-KEY', key: 'user', value: user.data.getUser })
        setMessageState('登録しました')
      } catch (error) {
        setMessageState('登録できませんでした')
        pushLog(errorlogData(state.session.attributes.sub, 34, new Error('update user data error.'), error))
      }

    }
  };

  /* ***********************************************
 *  Function delete hostname
 * ************************************************/
  const deleteHostname = async (websiteID) => {
    try {
      await API.graphql({
        query: mutations.deleteWebsite, variables: { input: { id: websiteID } }
      })
      const user = await API.graphql({ query: queries.getUser, variables: { id: state.session.attributes.sub } })
      dispatch({ type: 'UPDATE-KEY', key: 'user', value: user.data.getUser })
      setMessageState('削除しました')
    } catch (error) {
      setMessageState('削除できませんでした')
      pushLog(errorlogData(state.session.attributes.sub, 34, new Error('update user data error.'), error))
    }

  };

  /* ***********************************************
 *  Render
 * ************************************************/
  return (
    <>
      <section className="admin-register-site">
        <h3>利用サイトの登録</h3>
        <div className="section-body">
          <p>
            リアルタイムカウンターを表示させたいお手持ちの通販サイト、ホームページ、ブログ等のホスト名を入力してください。<br/>
            ※<code>http://</code>、<code>https://</code>等の表記は不要です。<br/>
            ※登録できるサイト数は{maxWebsiteState}つまでとなります。
          </p>
          <div className="submit-website">
            <p className="form-message">
              {messageState}
            </p>
            <div>
              <input
                id="hostname"
                name="hostname"
                type="text"
                value={hostnameState}
                placeholder='example.com'
                onChange={onChange}
              />
              <button 
                onClick={addHostname} 
                className={maxWebsiteState <= state.user.websites.items.length ? 'button-disabled':'button'}
                disabled={maxWebsiteState <= state.user.websites.items.length}>
                  {'登録'}
              </button>
            </div>
          </div>
          <div>
          </div>
        </div>
      </section>
      <section className="admin-registered-sites">
        <h3>登録済みサイト</h3>
        {state.user.websites.items.length === 0 &&
          <div className="section-body registered-site empty">
            <p>サイトが登録されていません</p>
          </div>
        }
        {state.user.websites.items.map((site, index) => {
          return (
            <div key={index} className="section-body registered-site">
              <p>
                <span>{site.hostname}</span>
                <span>{site.deleted ? '[無効]' : ''}</span>
              </p>
              <p>
                <button onClick={() => deleteHostname(site.id)} className="button">削除</button>
              </p>
            </div>
          )
        })}
      </section>
    </>
  )
}
