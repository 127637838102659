import { Auth } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SiteContext } from '../App'
import { pushLog } from '../functions';
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function SignUpConfirm() {
  const history = useHistory();

  /* ***********************************************
 *  state 
 * ************************************************/
  const { state, dispatch } = useContext(SiteContext)
  const [formState, updateFormState] = useState({ email: state.message.email, password: '', authCode: '' })

  /* ***********************************************
 *  Function redirect
 * ************************************************/
  useEffect(() => {
    history.push(state.redirect)
  }, [history, state.redirect])

  /* ***********************************************
 *  Function redirect
 * ************************************************/
  useEffect(() => {
    if (state.isSignedIn) {
      dispatch({ type: 'REDIRECT', paht: '/mypage' })
    }
  }, [dispatch, state.isSignedIn])

  /* ***********************************************
 *  Function amplify hub
 * ************************************************/
  const onChange = (e) => {
    e.preventDefault();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }))
  }

  /* ***********************************************
 *  Function amplify 
 * ************************************************/
  const onClick = async () => {
    try {
      await Auth.confirmSignUp(formState.email, formState.authCode)
    } catch (error) {
      dispatch({ type: 'UPDATE-MESSAGE', key: 'signUpConfirm', value: '認証に失敗しました' })
      pushLog({
        type: 'ERROR', file: 'SignUpConfirm.jsx', line: 47,
        userID: null, websiteID: null, client: null,
        newError: new Error(error.message),
        errorObject: error
      })
    }
    dispatch({ type: 'SIGNIN', email: formState.email, password: formState.password })
  }

  /* ***********************************************
  *  Render
  * ************************************************/
  return (
    <>
      <div className="admin before-login">
        <Header />
        <main>
          <section>
            <h2>
              {"新規登録 コード認証"}
            </h2>
            <p className="description">
              認証コードをメールで送信しました。<br/>
              メール記載の認証コード（6桁の数字）を入力し、認証ボタンを押してください。
            </p>
            <div className="signup-form">
              <input
                id="email"
                type="email"
                name="email"
                placeholder='メールアドレス'
                value={formState.email}
                onChange={onChange}
              />
              <input
                id="password"
                type="password"
                name="password"
                placeholder='パスワード'
                value={formState.password}
                onChange={onChange}
              />
              <input
                id="auth-code"
                type="text"
                name="authCode"
                placeholder='認証コード'
                value={formState.authCode}
                onChange={onChange}
              />
              <p className="form-message">
                {state.message.signUpConfirm}
              </p>
              <button onClick={onClick} className="button">
                {'認証'}
              </button>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </>
  )

}