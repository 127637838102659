import { Auth } from 'aws-amplify';
import React, { useContext, useState } from 'react';
import { SiteContext } from '../App'
import { isEmpty, passwordCheck, pushLog } from '../functions'

const initalFormState = {
  oldPassword: '',
  password: '',
  rePassword: ''
}

const errorlogData = (userID, line, error, object) => {
  return {
    type: 'ERROR',
    file: 'ChangePassword.jsx',
    userID: userID,
    websiteID: null,
    client: null,
    line: line,
    newError: error,
    errorObject: object
  }
}

export default function ChangePassword(props) {

  /* ***********************************************
 *  state 
 * ************************************************/
  const { state } = useContext(SiteContext)
  const [formState, updateFormState] = useState(initalFormState)
  const [messageState, setMessageState] = useState('')
  const [formEnableState, setFormEnableState] = useState(false)

  const onChange = (e) => {
    e.preventDefault();
    if (e.target.name === 'password') {
      const check = passwordCheck(e.target.value)
      setMessageState(check.message)
      setFormEnableState(check.enable)
    }
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }))
  }

  const onClick = () => {
    setMessageState('')
    if (isEmpty(formState.password) || isEmpty(formState.rePassword) || isEmpty(formState.oldPassword)) {
      setMessageState('パスワードが入力されていません')
      return null
    }

    if (formState.password !== formState.rePassword) {
      setMessageState('パスワードが一致しません')
      return null
    }

    if (formEnableState) {
      Auth.changePassword(state.session, formState.oldPassword, formState.password)
        .then(succese => setMessageState('パスワードを変更しました'))
        .catch(error => {
          setMessageState('パスワードの変更に失敗しました')
          pushLog(errorlogData(state.user.id, 58, new Error('change password error.'), error))
        })
    }

  };

  return (
    <>
      <section className="admin-changepassword">
        <h3>パスワード変更</h3>
        <div className="section-body">
          <div>
            <input
              id="oldPassword"
              name="oldPassword"
              type="password"
              placeholder='古いパスワード'
              onChange={onChange}
            />
            <input
              id="password"
              name="password"
              type="password"
              placeholder='新しいパスワード'
              onChange={onChange}
            />
            <input
              id="rePassword"
              name="rePassword"
              type="password"
              placeholder='新しいパスワード（確認）'
              onChange={onChange}
              required />
            <p className="form-message">
              {messageState}
            </p>
            <p className="form-message">
              {state.message.changePassword}
            </p>
            <button onClick={onClick} className="button">パスワードを変更</button>
          </div>
        </div>
      </section>
    </>
  )
}
