import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import {
  ForgotPassword,
  Home, MyPage, SignIn, SignUp, SignUpConfirm, TermsOfService, PrivacyPolicy
} from "./templates";

export default function Router() {

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' render={() => <Home />} />
        <Route exact path='/login' render={() => <SignIn />} />
        <Route exact path='/signup' render={() => <SignUp />} />
        <Route exact path='/signup-confirm' render={() => <SignUpConfirm />} />
        <Route exact path='/forgot-password' render={() => <ForgotPassword />} />
        <Route exact path='/mypage' render={() => <MyPage />} />
        <Route exact path='/terms' render={() => <TermsOfService />} />
        <Route exact path='/privacy' render={() => <PrivacyPolicy />} />
      </Switch>
    </BrowserRouter>
  );
}
