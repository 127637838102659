import isEmpty from './isEmpty'

export default function passwordCheck(password) {
  const ratz = /[a-z]/, rAtZ = /[A-Z]/, r0t9 = /[0-9]/;
  const messages = []
  const result = { enable: true, message: '' }

  if (isEmpty(password)) {
    return { enable: false, message: 'パスワードは必須です' }
  }

  if (!ratz.test(password)) {
    messages.push('「英小文字」')
    result.enable = false
  }

  if (!rAtZ.test(password)) {
    messages.push('「英大文字」')
    result.enable = false
  }

  if (!r0t9.test(password)) {
    messages.push('「数字」')
    result.enable = false
  }

  if (messages.length > 0) {
    result.message = messages.reduce((a, c) => a + '、' + c) + 'を含めてください'
  }
  if (password.length < 8) {
    result.message = '8文字以上で入力してください'
  }

  return result
}