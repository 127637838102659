import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SiteContext } from '../App'

export default function Footer(props) {
  
  const { state, dispatch } = useContext(SiteContext);
  
  /* ***********************************************
 *  Render
 * ************************************************/
  if (state.isSignedIn === true) {
    return (
      ''
    )
  } else {
    return (
      <footer>
        <nav className="wrapper">
          <Link to='/' onClick={() => dispatch({ type: 'REDIRECT', path: '/' })}>
            <picture><img src={require('../assets/images/lp/logo.svg').default} alt="Realtime Counter" /></picture>
          </Link>
          <ul>
            <li><Link to='/' onClick={() => dispatch({ type: 'REDIRECT', path: '/' })}>ホーム</Link></li>
            <li><a href="https://kobold-inc.com/" target="_blank" rel="noreferrer">運営会社</a></li>
            <li><Link to='/login' onClick={() => dispatch({ type: 'REDIRECT', path: '/login' })}>ログイン</Link></li>
            <li><Link to='/signup' onClick={() => dispatch({ type: 'REDIRECT', path: '/signup' })}>無料登録</Link></li>
          </ul>
        </nav>
        <div className="wrapper">
          <p className="copy">ネットショップ、ホームページの行列を見える化<br />「リアルタイムカウンター」</p>
          <p className="copyright">© 2022 realtimecounter.link</p>
        </div>
      </footer>
    )
  }

}