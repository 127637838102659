/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      serial
      authType
      updatedAt
      createdAt
      websites {
        items {
          id
          userID
          hostname
          styleType
          deleted
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      serial
      authType
      updatedAt
      createdAt
      websites {
        items {
          id
          userID
          hostname
          styleType
          deleted
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      serial
      authType
      updatedAt
      createdAt
      websites {
        items {
          id
          userID
          hostname
          styleType
          deleted
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const createWebsite = /* GraphQL */ `
  mutation CreateWebsite(
    $input: CreateWebsiteInput!
    $condition: ModelWebsiteConditionInput
  ) {
    createWebsite(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        username
        serial
        authType
        updatedAt
        createdAt
        websites {
          nextToken
        }
      }
      hostname
      styleType
      deleted
      updatedAt
      createdAt
      accessLog {
        items {
          id
          websiteID
          client
          url
          deleteTime
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const updateWebsite = /* GraphQL */ `
  mutation UpdateWebsite(
    $input: UpdateWebsiteInput!
    $condition: ModelWebsiteConditionInput
  ) {
    updateWebsite(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        username
        serial
        authType
        updatedAt
        createdAt
        websites {
          nextToken
        }
      }
      hostname
      styleType
      deleted
      updatedAt
      createdAt
      accessLog {
        items {
          id
          websiteID
          client
          url
          deleteTime
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const deleteWebsite = /* GraphQL */ `
  mutation DeleteWebsite(
    $input: DeleteWebsiteInput!
    $condition: ModelWebsiteConditionInput
  ) {
    deleteWebsite(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        username
        serial
        authType
        updatedAt
        createdAt
        websites {
          nextToken
        }
      }
      hostname
      styleType
      deleted
      updatedAt
      createdAt
      accessLog {
        items {
          id
          websiteID
          client
          url
          deleteTime
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const createAccessLog = /* GraphQL */ `
  mutation CreateAccessLog(
    $input: CreateAccessLogInput!
    $condition: ModelAccessLogConditionInput
  ) {
    createAccessLog(input: $input, condition: $condition) {
      id
      websiteID
      client
      url
      deleteTime
      updatedAt
      createdAt
    }
  }
`;
export const updateAccessLog = /* GraphQL */ `
  mutation UpdateAccessLog(
    $input: UpdateAccessLogInput!
    $condition: ModelAccessLogConditionInput
  ) {
    updateAccessLog(input: $input, condition: $condition) {
      id
      websiteID
      client
      url
      deleteTime
      updatedAt
      createdAt
    }
  }
`;
export const deleteAccessLog = /* GraphQL */ `
  mutation DeleteAccessLog(
    $input: DeleteAccessLogInput!
    $condition: ModelAccessLogConditionInput
  ) {
    deleteAccessLog(input: $input, condition: $condition) {
      id
      websiteID
      client
      url
      deleteTime
      updatedAt
      createdAt
    }
  }
`;
export const createErrorLog = /* GraphQL */ `
  mutation CreateErrorLog(
    $input: CreateErrorLogInput!
    $condition: ModelErrorLogConditionInput
  ) {
    createErrorLog(input: $input, condition: $condition) {
      id
      userID
      websiteID
      client
      message
      file
      line
      deleteTime
      updatedAt
      createdAt
    }
  }
`;
export const updateErrorLog = /* GraphQL */ `
  mutation UpdateErrorLog(
    $input: UpdateErrorLogInput!
    $condition: ModelErrorLogConditionInput
  ) {
    updateErrorLog(input: $input, condition: $condition) {
      id
      userID
      websiteID
      client
      message
      file
      line
      deleteTime
      updatedAt
      createdAt
    }
  }
`;
export const deleteErrorLog = /* GraphQL */ `
  mutation DeleteErrorLog(
    $input: DeleteErrorLogInput!
    $condition: ModelErrorLogConditionInput
  ) {
    deleteErrorLog(input: $input, condition: $condition) {
      id
      userID
      websiteID
      client
      message
      file
      line
      deleteTime
      updatedAt
      createdAt
    }
  }
`;
export const createInfoLog = /* GraphQL */ `
  mutation CreateInfoLog(
    $input: CreateInfoLogInput!
    $condition: ModelInfoLogConditionInput
  ) {
    createInfoLog(input: $input, condition: $condition) {
      id
      userID
      websiteID
      client
      message
      file
      line
      deleteTime
      updatedAt
      createdAt
    }
  }
`;
export const updateInfoLog = /* GraphQL */ `
  mutation UpdateInfoLog(
    $input: UpdateInfoLogInput!
    $condition: ModelInfoLogConditionInput
  ) {
    updateInfoLog(input: $input, condition: $condition) {
      id
      userID
      websiteID
      client
      message
      file
      line
      deleteTime
      updatedAt
      createdAt
    }
  }
`;
export const deleteInfoLog = /* GraphQL */ `
  mutation DeleteInfoLog(
    $input: DeleteInfoLogInput!
    $condition: ModelInfoLogConditionInput
  ) {
    deleteInfoLog(input: $input, condition: $condition) {
      id
      userID
      websiteID
      client
      message
      file
      line
      deleteTime
      updatedAt
      createdAt
    }
  }
`;
export const createWarningLog = /* GraphQL */ `
  mutation CreateWarningLog(
    $input: CreateWarningLogInput!
    $condition: ModelWarningLogConditionInput
  ) {
    createWarningLog(input: $input, condition: $condition) {
      id
      userID
      websiteID
      client
      message
      file
      line
      deleteTime
      updatedAt
      createdAt
    }
  }
`;
export const updateWarningLog = /* GraphQL */ `
  mutation UpdateWarningLog(
    $input: UpdateWarningLogInput!
    $condition: ModelWarningLogConditionInput
  ) {
    updateWarningLog(input: $input, condition: $condition) {
      id
      userID
      websiteID
      client
      message
      file
      line
      deleteTime
      updatedAt
      createdAt
    }
  }
`;
export const deleteWarningLog = /* GraphQL */ `
  mutation DeleteWarningLog(
    $input: DeleteWarningLogInput!
    $condition: ModelWarningLogConditionInput
  ) {
    deleteWarningLog(input: $input, condition: $condition) {
      id
      userID
      websiteID
      client
      message
      file
      line
      deleteTime
      updatedAt
      createdAt
    }
  }
`;
