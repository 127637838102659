import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { SiteContext } from '../App'
import { isEmpty } from '../functions'
import Header from '../components/Header';
import Footer from '../components/Footer';

const initalFormState = {
  email: '',
  password: '',
}

export default function SignIn() {

  const history = useHistory();
  /* ***********************************************
 *  state 
 * ************************************************/
  const { state, dispatch } = useContext(SiteContext)
  const [formState, updateFormState] = useState(initalFormState)
  const [messageState, setMessageState] = useState('')
  
  /* ***********************************************
 *  Function
 * ************************************************/
  const handleLink = (path) => {
    dispatch({ type: 'REDIRECT', path: path })
    history.push(path)
  }
  
  /* ***********************************************
 *  Function redirect
 * ************************************************/
  useEffect(() => {
    history.push(state.redirect)
  }, [history, state.redirect])

  /* ***********************************************
 *  Function redirect
 * ************************************************/
  useEffect(() => {
    if (state.isSignedIn) {
      dispatch({ type: 'REDIRECT', paht: '/mypage' })
    }
  }, [dispatch, state.isSignedIn])

  /* ***********************************************
 *  Function amplify hub
 * ************************************************/
  const onChange = (e) => {
    e.preventDefault();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }))
  }

  /* ***********************************************
 *  Function amplify hub
 * ************************************************/
  const onClick = (e) => {
    e.preventDefault();
    setMessageState('')
    if (isEmpty(formState.password) || isEmpty(formState.email)) {
      setMessageState('メールアドレス、またはパスワードが入力されていません')
    } else {
      dispatch({ type: 'SIGNIN', email: formState.email, password: formState.password })
    }

  }

  /* ***********************************************
 *  Render
 * ************************************************/
  return (
    <>
      <div className="admin before-login">
        <Header />
        <main>
          <section>
            <h2>ログイン</h2>
            <div className="signup-form">
              <input
                id="email"
                type="email"
                name="email"
                placeholder='メールアドレス'
                value={formState.email}
                onChange={onChange}
              />
              <input
                id="password"
                type="password"
                name="password"
                placeholder='パスワード'
                value={formState.password}
                onChange={onChange}
              />
              <p>
                <Link to='/forgot-password' onClick={() => handleLink('/forgot-password')}>パスワードを忘れた</Link>
              </p>
              <p className="form-message">
                {messageState}
              </p>
              <p className="form-message">
                {state.message.signin}
              </p>
              <button onClick={onClick} className="button">ログイン</button>
            </div>
            <p className="to-otherform">
              アカウントをお持でない方は
              <Link to='/signup' onClick={() => handleLink('/signup' )}>無料で作成</Link>
            </p>
          </section>
        </main>
        <Footer />
      </div>
    </>
  )
}
